import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Logo from "../components/Logo";
import Features from "../components/Features";
import EcmImageGallery from "../components/EcmImageGallery";

import { HTMLContent } from "../components/Content";

const IndexPageTemplate = ({ images, html, intro }) => (
  <div>
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column">
            <div className="notification is-danger is-light is-size-2">
              Although I love serving families during their birthing journeys, I
              am currently on pause from accepting new clients at this time.
            </div>
          </div>
        </div>
      </div>
    </section>
    <EcmImageGallery images={images} />
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <div className="columns is-centered is-mobile is-hidden-mobile">
                <div className="column is-half">
                  <Logo />
                </div>
              </div>
              <div className="columns">
                <div className="column is-12">
                  <HTMLContent className={"intro"} content={html} />
                </div>
              </div>
              <div className="columns">
                <div className="column is-12 has-text-centered">
                  <Link className="btn" to="/contact">
                    Contact me
                  </Link>
                </div>
              </div>
              <div className="content">
                <h3 className="has-text-weight-semibold is-size-2">
                  {intro.title}
                </h3>
                <Features gridItems={intro.blurbs} />
              </div>
              <div className="columns">
                <div className="column is-12 has-text-centered">
                  <Link className="btn" to="/services">
                    See all services
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      image: PropTypes.object,
    })
  ),
  title: PropTypes.string,
  pitches: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  intro: PropTypes.shape({
    title: PropTypes.string,
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        images={frontmatter.images}
        title={frontmatter.title}
        pitches={frontmatter.pitches}
        intro={frontmatter.intro}
        html={html}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        images {
          image {
            childImageSharp {
              fluid: gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                aspectRatio: 1.8
              )
              fixed: gatsbyImageData(
                layout: CONSTRAINED
                width: 150
                aspectRatio: 1
                transformOptions: { cropFocus: ENTROPY }
              )
            }
          }
          alt
        }
        intro {
          title
          blurbs {
            alt
            image {
              childImageSharp {
                gatsbyImageData(height: 360, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
        }
      }
    }
  }
`;
